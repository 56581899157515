<template>
  <div>
    <el-form :inline="true" :model="query" size="small">
      <el-form-item label="类型">
        <el-select v-model="query.type" placeholder="请选择" clearable>
          <el-option label="NFC" :value="1"></el-option>
          <el-option label="定位" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="日期范围">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="handleDateRangeChange"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery">查询</el-button>
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>

    <el-table v-loading="loading" :data="tableData" style="width: 100%" border height="500px">
      <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
      <el-table-column prop="location" label="巡更点"></el-table-column>
      <el-table-column prop="username" label="巡更人"></el-table-column>
      <el-table-column label="打卡类型">
        <template v-slot="{ row }">
          {{ row.type === 1 ? 'NFC' : '定位' }}
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="巡更时间"></el-table-column>
      <el-table-column label="照片">
        <template v-slot="{ row }">
          <el-image v-if="row.photo" style="width: 100px; height: 100px" :src="row.photo" :preview-src-list="[row.photo]">
          </el-image>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination
        background
        layout="prev, pager, next, jumper"
        :total="total"
        :current-page.sync="query.page"
        :page-size="query.page_size"
        @current-change="getBunchClockData(query)"
      >
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>
  </div>
</template>

<script>
import { getPunchClockDataAPI } from '@/api/punch-clock.js'
import { dateToYMD } from '@/utils/common-fun.js'

export default {
  data() {
    return {
      query: {
        page: 1,
        page_size: 10,
        type: '',
        start: '',
        end: '',
        export: 0,
      },
      dateRange: [],
      tableData: [],
      total: 0,
      loading: false,
    }
  },
  created() {
    this.setDefaultDate()
    this.getBunchClockData(this.query)
  },
  methods: {
    handleQuery() {
      this.query.page = 1
      this.getBunchClockData(this.query)
    },
    handleExport() {
      const queryCopy = Object.assign({}, this.query)
      queryCopy.export = 1
      getPunchClockDataAPI(queryCopy).then(res => {
        this.downloadFile(res, '巡更打卡记录')
      })
    },
    handleDateRangeChange(e) {
      if (e === null) {
        this.query.start = ''
        this.query.end = ''
      } else {
        this.query.start = e[0]
        this.query.end = e[1]
      }
    },
    downloadFile(url, filename) {
      try {
        const aElement = document.createElement('a')
        aElement.download = filename
        aElement.style.display = 'none'
        aElement.href = url
        document.body.appendChild(aElement)
        aElement.click()
        document.body.removeChild(aElement)
        this.$message.success('导出成功')
      } catch (error) {
        this.$message.error('导出失败')
      }
    },
    setDefaultDate() {
      let today = dateToYMD(new Date())
      this.dateRange = [today, today]
      this.query.start = today
      this.query.end = today
    },
    getBunchClockData(query) {
      this.loading = true
      getPunchClockDataAPI(query).then(res => {
        console.log('res', res)
        this.loading = false
        this.tableData = res.data
        this.total = res.total
      })
    },
  },
}
</script>

<style></style>
